import axios from "axios";

// const BASE_URL = "http://localhost:3012/api_umohe_com/rest/v1";
const BASE_URL = "https://api.umohe.com/rest/v1";

axios.interceptors.request.use(
  function (config) {
    config.headers.common["Accept"] = "application/json";
    config.headers.common["Content-Type"] = "application/json";
    if (localStorage.getItem("user.token")) {
      config.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("user.token");
      // console.log(localStorage.getItem("user.token"));
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default {
  baseUrl: BASE_URL,
  accounts: {
    entry: {
      login(data) {
        return axios.post(`${BASE_URL}/accounts/entry`, data);
      },
      forgot(data) {
        return axios.post(`${BASE_URL}/accounts/entry/forgot`, data);
      },
      changePassword(data) {
        return axios.post(`${BASE_URL}/accounts/entry/change_password`, data);
      },
    },
    contacts() {
      return axios.get(`${BASE_URL}/accounts/contacts`);
    },
    mySubs: {
      list() {
        return axios.get(`${BASE_URL}/accounts/mysubs`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/accounts/mysubs/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/accounts/mysubs/${id}`, data);
      },
      findBySubscriberTypeLoc(data) {
        return axios.post(`${BASE_URL}/accounts/mysubs/subtype_location`, data);
      },
      subscriberUsers() {
        return axios.get(`${BASE_URL}/accounts/mysubs/subscriber`);
      },
    },
    updatePassword(data) {
      return axios.put(`${BASE_URL}/accounts/changepass`, data);
    },
    find() {
      return axios.get(`${BASE_URL}/accounts`);
    },
    myPicture(data) {
      return axios.put(`${BASE_URL}/accounts/pix`, data);
    },
    profilePicture(data) {
      return axios.put(`${BASE_URL}/accounts/picture`, data);
    },
    updateProfile(data) {
      return axios.put(`${BASE_URL}/accounts`, data);
    },
    add(data) {
      return axios.post(`${BASE_URL}/accounts/add`, data);
    },
    active(id, data) {
      return axios.put(`${BASE_URL}/accounts/status/${id}`, data);
    },
    reviews: {
      create(data) {
        return axios.post(`${BASE_URL}/accounts/reviews`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/accounts/reviews`);
      },
      on(id) {
        return axios.get(`${BASE_URL}/accounts/reviews/on/${id}`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/accounts/reviews/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/accounts/reviews/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/accounts/reviews/${id}`);
      },
    },
    search: {
      findClient(data) {
        return axios.post(`${BASE_URL}/accounts/subscribers/clients`, data);
      },
    },
    profession: {
      update(data) {
        return axios.put(`${BASE_URL}/accounts/mysubs/certificates`, data);
      },
    },
  },
  general: {
    types: {
      dynamic() {
        return axios.get(`${BASE_URL}/general/types/dynamic`);
      },
    },
    country: {
      list() {
        return axios.get(`${BASE_URL}/general/country`);
      },
    },
    regions: {
      byCountry(id) {
        return axios.get(`${BASE_URL}/general/regions/country/${id}`);
      },
    },
    district: {
      byRegion(id) {
        return axios.get(`${BASE_URL}/general/districts/regions/${id}`);
      },
    },
    gender: {
      list() {
        return axios.get(`${BASE_URL}/general/gender`);
      },
    },
    salutations: {
      list() {
        return axios.get(`${BASE_URL}/general/salutations`);
      },
    },
    marriage: {
      list() {
        return axios.get(`${BASE_URL}/general/marital_status`);
      },
    },
    specialty: {
      list() {
        return axios.get(`${BASE_URL}/general/specialty`);
      },
      searchBySubType(id) {
        return axios.get(
          `${BASE_URL}/general/specialty/search_by_subtype/${id}`
        );
      },
    },
    specialtyDetails: {
      list(id) {
        return axios.get(`${BASE_URL}/general/specialdetail/specialty/${id}`);
      },
    },
    docType: {
      list() {
        return axios.get(`${BASE_URL}/general/doctypes`);
      },
    },
    infoTypes: {
      list() {
        return axios.get(`${BASE_URL}/general/infotypes`);
      },
    },
    meetypes: {
      list() {
        return axios.get(`${BASE_URL}/general/meetypes`);
      },
    },
    systemics: {
      list() {
        return axios.get(`${BASE_URL}/general/systemics`);
      },
      history() {
        return axios.get(`${BASE_URL}/general/systemics/history`);
      },
    },
    systemicsDetail: {
      list(id) {
        return axios.get(
          `${BASE_URL}/general/systemics/details/category/${id}`
        );
      },
    },
    labs: {
      list() {
        return axios.get(`${BASE_URL}/general/labcategory/my_request`);
      },
    },
    measurements: {
      byMeasurementCategory(id) {
        return axios.get(`${BASE_URL}/general/measurements/by/category/${id}`);
      },
    },
    roles: {
      list() {
        return axios.get(`${BASE_URL}/general/roles/manager`);
      },
    },
    medicat: {
      list() {
        return axios.get(`${BASE_URL}/general/medicat`);
      },
    },
    pharmacat: {
      list() {
        return axios.get(`${BASE_URL}/general/pharmacat`);
      },
      shortlist() {
        return axios.get(`${BASE_URL}/general/pharmacat/short`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/general/pharmacat/${id}`);
      },
    },
    paymentMethod: {
      list() {
        return axios.get(`${BASE_URL}/general/paymethods`);
      },
    },
    medicfreqency: {
      list() {
        return axios.get(`${BASE_URL}/general/medicfreqency`);
      },
    },
    medicroute: {
      list() {
        return axios.get(`${BASE_URL}/general/medicroute`);
      },
    },
    relationTo: {
      list() {
        return axios.get(`${BASE_URL}/general/relationto`);
      },
    },
    medicines: {
      list() {
        return axios.get(`${BASE_URL}/general/medicines`);
      },
      search(data) {
        return axios.post(`${BASE_URL}/general/medicines/search`, data);
      },
    },
    subscriberTypes: {
      nonPatient() {
        return axios.get(`${BASE_URL}/general/types/no_patients`);
      },
    },
  },
  subscribers: {
    detail() {
      return axios.get(`${BASE_URL}/accounts/subscribers`);
    },
    update(data) {
      return axios.put(`${BASE_URL}/accounts/subscribers`, data);
    },
    profileUpdate(data) {
      return axios.put(`${BASE_URL}/accounts/subscribers/profile`, data);
    },
    clients(data) {
      return axios.post(`${BASE_URL}/accounts/subscribers/clients`, data);
    },
    patients(data) {
      return axios.post(`${BASE_URL}/accounts/subscribers/patients`, data);
    },
    treatments: {},
    available: {
      create(data) {
        return axios.post(`${BASE_URL}/medics/available`, data);
      },
      add(data) {
        return axios.post(`${BASE_URL}/medics/available/add`, data);
      },
      available() {
        return axios.get(`${BASE_URL}/medics/available`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/medics/available/${id}`);
      },
      availableDays(id) {
        return axios.get(`${BASE_URL}/medics/available/days/${id}`);
      },
      mySchedules() {
        return axios.get(`${BASE_URL}/medics/available/my/schedule`);
      },
      mySchedulesTimes(data) {
        return axios.post(
          `${BASE_URL}/medics/available/my/schedule/times`,
          data
        );
      },
      mySchedulesTimesDetail(data) {
        return axios.post(
          `${BASE_URL}/medics/available/my/schedule/times/details`,
          data
        );
      },
      scheduleDate(date) {
        return axios.get(`${BASE_URL}/medics/available/date/${date}`);
      },
      availtimeDetails(data) {
        return axios.post(`${BASE_URL}/medics/available/times_details`, data);
      },
      scheduleTime(data) {
        return axios.post(`${BASE_URL}/medics/available/times`, data);
      },
      timeDetail(id) {
        return axios.get(`${BASE_URL}/medics/available/time/${id}`);
      },
      availableTimeDetail(data) {
        return axios.post(`${BASE_URL}/medics/available/time`, data);
      },
      removeTime(id) {
        return axios.delete(`${BASE_URL}/medics/available/time/${id}`);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/medics/available/${id}`);
      },
      isDone(id, data) {
        return axios.put(`${BASE_URL}/medics/available/is_done/${id}`, data);
      },
    },
    background: {
      create(data) {
        return axios.post(`${BASE_URL}/accounts/subscribers/background`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/accounts/subscribers/background`);
      },
      preview(id) {
        return axios.get(
          `${BASE_URL}/accounts/subscribers/background/preview/${id}`
        );
      },
      find(id) {
        return axios.get(`${BASE_URL}/accounts/subscribers/background/${id}`);
      },
      update(id, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/background/${id}`,
          data
        );
      },
      remove(id) {
        return axios.delete(
          `${BASE_URL}/accounts/subscribers/background/${id}`
        );
      },
    },
    certificate: {
      create(data) {
        return axios.post(`${BASE_URL}/accounts/subscribers/docs`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/accounts/subscribers/docs`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/accounts/subscribers/docs/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/accounts/subscribers/docs/${id}`, data);
      },
      upload(id, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/docs/file/${id}`,
          data
        );
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/accounts/subscribers/docs/${id}`);
      },
    },
    services: {
      create(data) {
        return axios.post(`${BASE_URL}/accounts/subscribers/services`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/accounts/subscribers/services`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/accounts/subscribers/services/${id}`);
      },
      update(id, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/services/${id}`,
          data
        );
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/accounts/subscribers/services/${id}`);
      },
    },
    proServices: {
      create(a) {
        return axios.post(`${BASE_URL}/accounts/subscribers/proservices`, a);
      },
      list() {
        return axios.get(`${BASE_URL}/accounts/subscribers/proservices`);
      },
      find(a) {
        return axios.get(`${BASE_URL}/accounts/subscribers/proservices/${a}`);
      },
      update(a, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/proservices/${a}`,
          data
        );
      },
      findMeet(a) {
        return axios.get(
          `${BASE_URL}/accounts/subscribers/proservices/meet/${a}`
        );
      },
      updateActive(a, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/proservices/active/${a}`,
          data
        );
      },
      updateDiscount(a, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/proservices/discount/${a}`,
          data
        );
      },
      remove(id) {
        return axios.delete(
          `${BASE_URL}/accounts/subscribers/proservices/${id}`
        );
      },
    },
    subsidiary: {
      create(data) {
        return axios.post(`${BASE_URL}/accounts/subscribers/subsidiary`, data);
      },
      list() {
        return axios.get(`${BASE_URL}/accounts/subscribers/subsidiary`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/accounts/subscribers/subsidiary/${id}`);
      },
      update(id, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/subsidiary/${id}`,
          data
        );
      },
      updateSchedule(id, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/subsidiary/timetable/${id}`,
          data
        );
      },
      updateHead(id, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/subsidiary/head/${id}`,
          data
        );
      },
      geo(id, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/subsidiary/geo/${id}`,
          data
        );
      },
      remove(id) {
        return axios.delete(
          `${BASE_URL}/accounts/subscribers/subsidiary/${id}`
        );
      },
    },
    subservice: {
      create(data) {
        return axios.post(`${BASE_URL}/accounts/subscribers/subservices`, data);
      },
      list(id) {
        return axios.get(
          `${BASE_URL}/accounts/subscribers/subservices/list/${id}`
        );
      },
      find(id) {
        return axios.get(`${BASE_URL}/accounts/subscribers/subservices/${id}`);
      },
      update(id, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/subservices/${id}`,
          data
        );
      },
      remove(id) {
        return axios.delete(
          `${BASE_URL}/accounts/subscribers/subservices/${id}`
        );
      },
    },
    pharma_stocks: {
      create(data) {
        return axios.post(
          `${BASE_URL}/accounts/subscribers/pharma_stocks`,
          data
        );
      },
      list() {
        return axios.get(`${BASE_URL}/accounts/subscribers/pharma_stocks`);
      },
      mediclist() {
        return axios.get(`${BASE_URL}/accounts/subscribers/pharma_stocks/list`);
      },
      find(id) {
        return axios.get(
          `${BASE_URL}/accounts/subscribers/pharma_stocks/${id}`
        );
      },
      update(id, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/pharma_stocks/${id}`,
          data
        );
      },
      remove(id) {
        return axios.delete(
          `${BASE_URL}/accounts/subscribers/pharma_stocks/${id}`
        );
      },
      status(id, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/pharma_stocks/status/${id}`,
          data
        );
      },
    },
    substocks: {
      create(data) {
        return axios.post(`${BASE_URL}/accounts/subscribers/substocks`, data);
      },
      list(id) {
        return axios.get(
          `${BASE_URL}/accounts/subscribers/substocks/list/${id}`
        );
      },
      find(id) {
        return axios.get(`${BASE_URL}/accounts/subscribers/substocks/${id}`);
      },
      update(id, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/substocks/${id}`,
          data
        );
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/accounts/subscribers/substocks/${id}`);
      },
    },
    myMeet: {
      create(data) {
        return axios.post(
          `${BASE_URL}/accounts/subscribers/my_meeting_types`,
          data
        );
      },
      find(id) {
        return axios.get(
          `${BASE_URL}/accounts/subscribers/my_meeting_types/${id}`
        );
      },
      list() {
        return axios.get(`${BASE_URL}/accounts/subscribers/my_meeting_types`);
      },
      subscriberList(data) {
        return axios.post(
          `${BASE_URL}/accounts/subscribers/my_meeting_types/subscriber`,
          data
        );
      },
      update(id, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/my_meeting_types/${id}`,
          data
        );
      },
      updateActive(id, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/my_meeting_types/active/${id}`,
          data
        );
      },
      updateDisc(id, data) {
        return axios.put(
          `${BASE_URL}/accounts/subscribers/my_meeting_types/discount/${id}`,
          data
        );
      },
      remove(id) {
        return axios.delete(
          `${BASE_URL}/accounts/subscribers/my_meeting_types/${id}`
        );
      },
    },
  },
  medics: {
    request: {
      patientRequest(data) {
        return axios.post(`${BASE_URL}/medics/request`, data);
      },
    },
    patient: {
      initial: {
        complaints(id) {
          return axios.get(`${BASE_URL}/medics/patient/initial/${id}`);
        },
        more(id) {
          return axios.get(`${BASE_URL}/medics/patient/initial/more/${id}`);
        },
      },
      request: {
        create(data) {
          return axios.post(`${BASE_URL}/medics/patient/request`, data);
        },
        medic(data) {
          return axios.post(`${BASE_URL}/medics/patient/request/medic`, data);
        },
        find(id) {
          return axios.get(`${BASE_URL}/medics/patient/request/find/${id}`);
        },
        update(id, data) {
          return axios.put(`${BASE_URL}/medics/patient/request/${id}`, data);
        },
        delete(availaid, reqid) {
          console.log("availaid:", availaid);
          console.log("reqid:", reqid);
          return axios.delete(
            `${BASE_URL}/medics/patient/request/avail/${availaid}/${reqid}`
          );
        },
        finished(data) {
          return axios.put(`${BASE_URL}/medics/patient/request/finished`, data);
        },
        history(data) {
          return axios.post(`${BASE_URL}/medics/patient/request/history`, data);
        },
        subscribers() {
          return axios.get(`${BASE_URL}/medics/patient/request/subscribers`);
        },
      },
      appointment() {
        return axios.get(`${BASE_URL}/medics/patient/request`);
      },
      past() {
        return axios.get(`${BASE_URL}/medics/patient/request/past`);
      },
      appointDetails(id) {
        return axios.get(`${BASE_URL}/medics/patient/request/${id}`);
      },
      appointmentProvider() {
        return axios.get(`${BASE_URL}/medics/patient/request/provider`);
      },
      appointmentsDone() {
        return axios.get(`${BASE_URL}/medics/patient/request/done`);
      },
      acceptance(id, data) {
        return axios.put(
          `${BASE_URL}/medics/patient/request/acceptance/${id}`,
          data
        );
      },
      dependancy: {
        dependancy(data) {
          return axios.get(`${BASE_URL}/medics/patient/dependancy/${data}`);
        },
        swithchAcc(data) {
          return axios.post(
            `${BASE_URL}/medics/patient/dependancy/switch`,
            data
          );
        },
      },
      paid(id, data) {
        return axios.put(`${BASE_URL}/medics/patient/request/paid/${id}`, data);
      },
    },
    patientMoreInfo: {
      create(data) {
        return axios.post(`${BASE_URL}/medics/patient/requestmoreinfo`, data);
      },
      list(id) {
        return axios.get(
          `${BASE_URL}/medics/patient/requestmoreinfo/list/${id}`
        );
      },
      find(id) {
        return axios.get(`${BASE_URL}/medics/patient/requestmoreinfo/${id}`);
      },
      update(id, data) {
        return axios.put(
          `${BASE_URL}/medics/patient/requestmoreinfo/${id}`,
          data
        );
      },
      delete(id) {
        return axios.delete(`${BASE_URL}/medics/patient/requestmoreinfo/${id}`);
      },
    },
    evidence: {
      create(data) {
        return axios.post(`${BASE_URL}/medics/patient/evidences`, data);
      },
      list(id) {
        return axios.get(`${BASE_URL}/medics/patient/evidences/list/${id}`);
      },
      find(id) {
        return axios.get(`${BASE_URL}/medics/patient/evidences/${id}`);
      },
      update(id, data) {
        return axios.put(`${BASE_URL}/medics/patient/evidences/${id}`, data);
      },
      remove(id) {
        return axios.delete(`${BASE_URL}/medics/patient/evidences/${id}`);
      },
    },
    treatment: {
      providerList() {
        return axios.get(`${BASE_URL}/medics/patient/treatment`);
      },
      diagnostics: {
        create(data) {
          return axios.post(`${BASE_URL}/medics/treatments/diagnostics`, data);
        },
        list(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/diagnostics/list/${id}`
          );
        },
        find(id) {
          return axios.get(`${BASE_URL}/medics/treatments/diagnostics/${id}`);
        },
        update(id, data) {
          return axios.put(
            `${BASE_URL}/medics/treatments/diagnostics/${id}`,
            data
          );
        },
        remove(id) {
          return axios.delete(
            `${BASE_URL}/medics/treatments/diagnostics/${id}`
          );
        },
      },
      history: {
        create(data) {
          return axios.post(`${BASE_URL}/medics/treatments/history`, data);
        },
        list(id) {
          return axios.get(`${BASE_URL}/medics/treatments/history/list/${id}`);
        },
        find(id) {
          return axios.get(`${BASE_URL}/medics/treatments/history/${id}`);
        },
        update(id, data) {
          return axios.put(`${BASE_URL}/medics/treatments/history/${id}`, data);
        },
        remove(id) {
          return axios.delete(`${BASE_URL}/medics/treatments/history/${id}`);
        },
      },
      enquiry: {
        add(data) {
          return axios.post(`${BASE_URL}/medics/treatments/enquiry`, data);
        },
        upload(data) {
          return axios.post(
            `${BASE_URL}/medics/treatments/enquiry/upload`,
            data
          );
        },
        list(id) {
          return axios.get(`${BASE_URL}/medics/treatments/enquiry/list/${id}`);
        },
        active(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/enquiry/active/${id}`
          );
        },
        forEdits(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/enquiry/forEdit/${id}`
          );
        },
        accepted(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/enquiry/accepted/${id}`
          );
        },
        find(id) {
          return axios.get(`${BASE_URL}/medics/treatments/enquiry/${id}`);
        },
        update(id, data) {
          return axios.put(`${BASE_URL}/medics/treatments/enquiry/${id}`, data);
        },
        remove(id) {
          return axios.delete(`${BASE_URL}/medics/treatments/enquiry/${id}`);
        },
      },
      examfind: {
        create(data) {
          return axios.post(`${BASE_URL}/medics/treatments/examfind`, data);
        },
        list(id) {
          return axios.get(`${BASE_URL}/medics/treatments/examfind/list/${id}`);
        },
        find(id) {
          return axios.get(`${BASE_URL}/medics/treatments/examfind/${id}`);
        },
        update(id, data) {
          return axios.put(
            `${BASE_URL}/medics/treatments/examfind/${id}`,
            data
          );
        },
      },
      diagnosis: {
        searching(data) {
          return axios.post(
            `${BASE_URL}/medics/treatments/diagnosis/search`,
            data
          );
        },
        entity(id) {
          return axios.get(`${BASE_URL}/medics/treatments/diagnosis/${id}`);
        },
        linearSearch(data) {
          return axios.post(
            `${BASE_URL}/medics/treatments/diagnosis/linear/search`,
            data
          );
        },
        linearFind(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/diagnosis/linear/${id}`
          );
        },
      },
      prescription: {
        upload(data) {
          return axios.post(`${BASE_URL}/medics/treatments/presrequest`, data);
        },
        list(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/presrequest/list/${id}`
          );
        },
        finished(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/presrequest/finished/${id}`
          );
        },
        update(id, data) {
          return axios.put(
            `${BASE_URL}/medics/treatments/presrequest/${id}`,
            data
          );
        },
        remove(id) {
          return axios.delete(
            `${BASE_URL}/medics/treatments/presrequest/${id}`
          );
        },
      },
      labs: {
        create(data) {
          return axios.post(`${BASE_URL}/medics/treatments/labrequest`, data);
        },
        list(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/labrequest/list/${id}`
          );
        },
        accepted(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/labrequest/accepted/${id}`
          );
        },
        find(id) {
          return axios.get(`${BASE_URL}/medics/treatments/labrequest/${id}`);
        },
        update(data) {
          return axios.put(`${BASE_URL}/medics/treatments/labrequest`, data);
        },
        remove(id) {
          return axios.delete(`${BASE_URL}/medics/treatments/labrequest/${id}`);
        },
        finished(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/labrequest/finished/${id}`
          );
        },
        medicav(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/labrequest/medav/${id}`
          );
        },
        offering(id) {
          return axios.get(
            `${BASE_URL}/medics/treatments/labrequest/services/${id}`
          );
        },
      },
      labResponse: {
        upload(data) {
          return axios.post(
            `${BASE_URL}/medics/treatments/labresponse/response`,
            data
          );
        },
      },
      summary: {
        list(data) {
          return axios.post(`${BASE_URL}/medics/treatments/summary`, data);
        },
        find(data) {
          return axios.post(`${BASE_URL}/medics/treatments/summary/find`, data);
        },
      },
      transact: {
        prospect: {
          lab: {
            create(data) {
              return axios.post(
                `${BASE_URL}/medics/treatments/transaction/prospect/lab`,
                data
              );
            },
            list(id) {
              return axios.post(
                `${BASE_URL}/medics/treatments/transaction/prospect/lab/list/${id}`
              );
            },
            submitted(id) {
              return axios.get(
                `${BASE_URL}/medics/treatments/transaction/prospect/lab/sumitted/${id}`
              );
            },
            find(id) {
              return axios.post(
                `${BASE_URL}/medics/treatments/transaction/prospect/lab/${id}`
              );
            },
            accept(id) {
              return axios.post(
                `${BASE_URL}/medics/treatments/transaction/prospect/lab/acceptance/${id}`
              );
            },
            done(id) {
              return axios.post(
                `${BASE_URL}/medics/treatments/transaction/prospect/lab/done/${id}`
              );
            },
            remove(id) {
              return axios.post(
                `${BASE_URL}/medics/treatments/transaction/prospect/lab/${id}`
              );
            },
            requests(id) {
              return axios.get(
                `${BASE_URL}/medics/treatments/transaction/prospect/lab/requested/${id}`
              );
            },
            findMedics(data) {
              return axios.post(
                `${BASE_URL}/medics/treatments/transaction/prospect/lab/labs`,
                data
              );
            },
            checkStatus(data) {
              return axios.post(
                `${BASE_URL}/medics/treatments/transaction/prospect/lab/labstatus`,
                data
              );
            },
            responses(d) {
              return axios.post(
                `${BASE_URL}/medics/treatments/transaction/prospect/lab/responses`,
                d
              );
            },
            responseDetail(d) {
              return axios.get(
                `${BASE_URL}/medics/treatments/transaction/prospect/lab/responses/${d}`
              );
            },
          },
          prescribe: {
            create(data) {
              return axios.post(
                `${BASE_URL}/medics/treatments/transaction/prospect/prescribe`,
                data
              );
            },
            check(id, sub) {
              return axios.get(
                `${BASE_URL}/medics/treatments/transaction/prospect/prescribe/check/${id}/${sub}`
              );
            },
            submitted(id) {
              return axios.get(
                `${BASE_URL}/medics/treatments/transaction/prospect/prescribe/submitted/${id}`
              );
            },
            client(bool) {
              return axios.get(
                `${BASE_URL}/medics/treatments/transaction/prospect/prescribe/client/${bool}`
              );
            },
            findMedics(data) {
              return axios.post(
                `${BASE_URL}/medics/treatments/transaction/prospect/prescribe/medics`,
                data
              );
            },
            detailMedic(id) {
              return axios.post(
                `${BASE_URL}/medics/treatments/transaction/prospect/prescribe/medics/details`,
                id
              );
            },
            accept(id, data) {
              return axios.put(
                `${BASE_URL}/medics/treatments/transaction/prospect/prescribe/accept/${id}`,
                data
              );
            },
          },
        },
        providers: {
          labs: {
            requestList() {
              return axios.get(`${BASE_URL}/patient/req/lab`);
            },
            detail(id) {
              return axios.get(`${BASE_URL}/patient/req/lab/detail/${id}`);
            },
          },
        },
      },
    },
  },
  cart: {
    create(data) {
      return axios.post(`${BASE_URL}/cart`, data);
    },
    client() {
      return axios.get(`${BASE_URL}/cart/client`);
    },
    remove(id) {
      return axios.delete(`${BASE_URL}/cart/${id}`);
    },
    // find(data) {
    //   return axios.post(`${BASE_URL}/cart`, data);
    // },
  },
  checkout: {
    checkout(data) {
      return axios.post(`${BASE_URL}/checkout`, data);
    },
    myKeys(data) {
      return axios.post(`${BASE_URL}/checkout/public`, data);
    },
    cc(data) {
      return axios.post(`${BASE_URL}/checkout/cc`, data);
    },
    momo(data) {
      return axios.post(`${BASE_URL}/checkout/momo`, data);
    },
    verifyMomo(data) {
      return axios.post(`${BASE_URL}/checkout/verify_momo`, data);
    },
    transactions() {
      return axios.get(`${BASE_URL}/checkout/transactions`);
    },
    verify(data) {
      return axios.post(`${BASE_URL}/checkout/verify`, data);
    },
    paid(data) {
      return axios.post(`${BASE_URL}/checkout/paid`, data);
    },
    cash(data) {
      return axios.post(`${BASE_URL}/checkout/cash`, data);
    },
  },
  ratings: {
    subscribers: {
      create(d) {
        return axios.post(`${BASE_URL}/rating/subscriber`, d);
      },
      check(d) {
        return axios.post(`${BASE_URL}/rating/subscriber/check`, d);
      },
      userlist() {
        return axios.get(`${BASE_URL}/rating/subscriber/users`);
      },
      subscriberList() {
        return axios.get(`${BASE_URL}/rating/subscriber/subscriber`);
      },
      find() {},
      update(id, d) {
        return axios.put(`${BASE_URL}/rating/subscriber/${id}`, d);
      },
      delete() {},
    },
  },
  searching: {
    searchText(d) {
      return axios.post(`${BASE_URL}/searching/subscribers`, d);
    },
    subType(d) {
      return axios.post(`${BASE_URL}/searching/subscribers/subtype`, d);
    },
    searchBySpecialization(d) {
      return axios.post(`${BASE_URL}/searching/subscribers/specialization`, d);
    },
    searchByCity(d) {
      return axios.post(`${BASE_URL}/searching/subscribers/city`, d);
    },
  },
  blogs: {
    create(d) {
      return axios.post(`${BASE_URL}/blogs`, d);
    },
    userlist() {
      return axios.get(`${BASE_URL}/blogs/list`);
    },
    find(id) {
      return axios.get(`${BASE_URL}/blogs/${id}`);
    },
    active() {
      return axios.get(`${BASE_URL}/blogs/active`);
    },
    update(id, d) {
      return axios.put(`${BASE_URL}/blogs/${id}`, d);
    },
    delete(id) {
      return axios.delete(`${BASE_URL}/blogs/${id}`);
    },
  },
};
